import {
  useCallback,
  useContext,
  useEffect,
  ChangeEvent,
  useState,
} from "react";
import { Trans } from "@lingui/macro";
import {
  AvailableDebitSchemeEnum,
  BillingRequestFlowResource,
  BillingRequestResource,
  BillingRequestsStatus,
} from "@gocardless/api/dashboard/types";
import { billingRequestUpdate } from "@gocardless/api/dashboard/billing-request";
import {
  Box,
  Text,
  JustifyContent,
  AlignItems,
  Space,
  Color,
  Dialog,
  Select,
  Visibility,
  Option,
  ButtonVariant,
  Interpose,
} from "@gocardless/flux-react";
import { Image } from "src/components/shared/Image";
import BrandedLink from "src/components/shared/BrandedComponents/BrandedLink";
import Modals from "src/components/shared/Modals";
import { Routes } from "src/components/shared/Router";
import { translateForScheme } from "src/common/scheme-translations/translateForScheme";
import {
  BrandedComponentType,
  findBestMatchLocale,
  getBrandColorFor,
  isMandateOnlyFlow,
  merchantSchemeIdentifier,
} from "src/common/utils";
import {
  LocaleNames,
  Locale,
  useI18n,
  ShortLocalNames,
  ShortToLongLocalNames,
  ShortLocale,
} from "src/common/i18n";
import { RuntimeMode } from "src/state/RuntimeModeInitialiser";
import { GlobalState, PayerThemeType } from "src/state";
import { Logger } from "src/common/logger";
import { useSegment } from "src/shared/Segment/useSegment";
import { Scheme, customiseForScheme } from "src/common/schemeCustomisations";
import { useLingui } from "@lingui/react";
import { isIncludedPaymentScheme } from "src/common/schemeCustomisations/PaymentSchemeCustomisations/isIncludedPaymentScheme";
import {
  PaymentScheme,
  customiseForPaymentScheme,
} from "src/common/schemeCustomisations/PaymentSchemeCustomisations/PaymentSchemeCustomisations";

export interface FooterProps {
  runtimeMode?: RuntimeMode;
  billingRequest?: BillingRequestResource;
  billingRequestFlow?: BillingRequestFlowResource;
  page?: Routes;
  payerTheme?: PayerThemeType;
}

const Footer = ({
  runtimeMode,
  billingRequest,
  billingRequestFlow,
  page,
  payerTheme,
}: FooterProps) => {
  const { i18n } = useLingui();
  const isDropinMode = !!(runtimeMode && runtimeMode === RuntimeMode.Dropin);

  const titleColor = isDropinMode ? Color.Greystone_700 : Color.Greystone_1400;
  const textColor = isDropinMode ? Color.Greystone_700 : Color.Greystone_1100;

  const [modal, setModal] = useState<string>("");
  const { sendEvent } = useSegment();
  const email = billingRequestFlow?.config?.merchant_contact_details?.email;
  const phone_number =
    billingRequestFlow?.config?.merchant_contact_details?.phone_number;
  const mandateScheme = billingRequest?.mandate_request
    ?.scheme as AvailableDebitSchemeEnum;
  const paymentScheme = billingRequest?.payment_request
    ?.scheme as PaymentScheme;
  const hasScheme = paymentScheme ?? mandateScheme;
  const billingRequestId = billingRequest?.id;
  const billingRequestStatus = billingRequest?.status;
  const isPaymentProvider = !!billingRequest?.links?.payment_provider;
  // This show locale dropdown only when scheme is defined i.e. BR is present
  const isLocaleSwitcherEnabled =
    (mandateScheme &&
      customiseForScheme({
        scheme: mandateScheme as Scheme,
        key: "billing-request.show-locale-switcher",
      })) ||
    (paymentScheme &&
      customiseForScheme({
        scheme: paymentScheme,
        key: "billing-request.show-locale-switcher",
      }));

  const showFooter = hasScheme;
  const creditorName = billingRequest?.creditor_name;

  // for mandate-only flow footer is allowed
  const pagesToAllowFooterForMandateOnlyFlow = [
    Routes.CollectBankAccount,
    Routes.CollectCustomerDetails,
    Routes.Consent,
    Routes.BankSelect,
    Routes.BankConnectRequest,
    Routes.BankConnect,
    Routes.BankWait,
    Routes.BankAccountSelect,
    Routes.BankConfirm,
    Routes.Success,
  ];

  // for flows with payment (payment-only and dual)
  // footer is allowed on the all pages
  const pagesToAllowFooterForPaymentFlows = [
    Routes.BankPay,
    Routes.CollectBankAccount,
    Routes.CollectCustomerDetails,
    Routes.BankSelect,
    Routes.BankConfirm,
    Routes.BankConnect,
    Routes.BankWait,
    Routes.Success,
  ];

  let gocardlessRegistrationInformation: React.ReactElement | null = null;
  let directDebitGuarantee: React.ReactElement | null = null;
  let legalNotice: React.ReactElement | null = null;

  const mandateOnly = isMandateOnlyFlow(billingRequest);

  if (showFooter && page) {
    if (mandateScheme) {
      if (pagesToAllowFooterForMandateOnlyFlow.includes(page)) {
        gocardlessRegistrationInformation = translateForScheme({
          scheme: mandateScheme,
          translationKey:
            "billing-request.footer.gocardless-registration-information",
          params: { payerTheme, isDropinMode, creditorName, i18n },
        });

        if (mandateOnly || page !== Routes.BankConfirm) {
          directDebitGuarantee = translateForScheme({
            scheme: mandateScheme,
            translationKey: "billing-request.footer.direct-debit-guarantee",
            params: { payerTheme, isDropinMode, setModal, sendEvent },
          });
        }
      }

      // for mandate-only and dual flow
      // footer should contain legal note on confirmation screen
      const sun = merchantSchemeIdentifier(
        billingRequestFlow?.config?.scheme_identifiers,
        mandateScheme
      );

      legalNotice = translateForScheme({
        scheme: mandateScheme,
        translationKey: "confirm-details-page.footer.legal-notice",
        params: {
          sunBankStatementName: sun?.bank_statement_name,
          sunAdvanceNotice: sun?.advance_notice,
          sunRegisteredName: sun?.name,
          supportEmail: email,
          creditorName,
          i18n,
          payerTheme,
          isDropinMode,
        },
      });
    }

    if (
      paymentScheme &&
      pagesToAllowFooterForPaymentFlows.includes(page) &&
      isIncludedPaymentScheme(paymentScheme)
    ) {
      gocardlessRegistrationInformation = customiseForPaymentScheme({
        scheme: paymentScheme,
        key: "billing-request.footer.gocardless-registration-information",
        params: { payerTheme, isDropinMode, i18n },
      });
    }
  }

  const commonFooterContent = (
    <Interpose node={<Space v={1} />}>
      {legalNotice && (
        <Box data-testid="footer.legal-notice">
          <Text color={textColor} size={[1, null, 2]}>
            {legalNotice}
          </Text>
        </Box>
      )}
      {directDebitGuarantee && (
        <Box data-testid="footer.direct-debit-guarantee">
          <Text color={textColor} size={[1, null, 2]}>
            {directDebitGuarantee}
          </Text>
        </Box>
      )}

      {gocardlessRegistrationInformation && (
        <Box data-testid="footer.gocardless-registration-information">
          <Text color={textColor} size={[1, null, 2]}>
            {gocardlessRegistrationInformation}
          </Text>
        </Box>
      )}
    </Interpose>
  );

  return (
    <Box
      justifyContent={JustifyContent.Center}
      alignItems={AlignItems.Center}
      layout="flex"
      flexDirection="column"
      fontSize={2}
      css={{ zIndex: 1 }}
      className="fs-unmask"
    >
      <Space v={1} />
      <Dialog
        open={!!modal}
        aria-labelledby="extraContent"
        closeAction={{ onClose: () => setModal(""), label: "" }}
      >
        <Modals modal={modal} onClose={() => setModal("")} />
      </Dialog>
      <Box layout="flex" alignItems={AlignItems.Center}>
        {!isPaymentProvider && (
          <Box
            justifyContent={JustifyContent.Center}
            alignItems={AlignItems.Center}
            layout="flex"
            spaceBelow={1}
          >
            <Text size={2} color={titleColor}>
              <Trans id="billing-rerquest-flow.footer.securely-powered-by-text">
                Securely powered by
              </Trans>
            </Text>
            <Space h={0.5} layout="inline" />
            <Image
              name="GCRebrandLogo"
              alt="GoCardless"
              width="108px"
              height="20px"
              css={
                isDropinMode
                  ? {
                      "svg > path ": {
                        fill: Color.White,
                      },
                    }
                  : {}
              }
            />
          </Box>
        )}

        {isLocaleSwitcherEnabled && (
          <Visibility visible={["none", "inline-block"]}>
            <LocaleSwitcher
              forDevice="desktop"
              billingRequestId={billingRequestId as string}
              isFulfilled={
                billingRequestStatus === BillingRequestsStatus.Fulfilled
              }
              billingRequest={billingRequest}
              billingRequestFlow={billingRequestFlow}
            />
          </Visibility>
        )}
      </Box>
      {showFooter && isPaymentProvider ? (
        <Box
          css={{
            textAlign: "center",
          }}
          fontSize={1}
          color={textColor}
        >
          <Text color={textColor} size={2}>
            {commonFooterContent}
          </Text>
          <Space v={1} />
        </Box>
      ) : showFooter ? (
        <Box
          css={{
            textAlign: "center",
          }}
          color={textColor}
        >
          {email && (
            <BrandedLink
              textColor={getBrandColorFor(
                BrandedComponentType.FooterLink,
                payerTheme,
                isDropinMode
              )}
              href={`mailto:${email}`}
              variant={ButtonVariant.Inline}
            >
              {email}
            </BrandedLink>
          )}
          {email && phone_number && " | "}
          {phone_number && (
            <BrandedLink
              textColor={getBrandColorFor(
                BrandedComponentType.FooterLink,
                payerTheme,
                isDropinMode
              )}
              href={`tel:${phone_number}`}
              variant={ButtonVariant.Inline}
            >
              {phone_number}
            </BrandedLink>
          )}
          <Space v={1} />
          <Text color={textColor} size={2}>
            {commonFooterContent}
          </Text>
          <Space v={1} />
        </Box>
      ) : null}
      {isLocaleSwitcherEnabled && (
        <Visibility visible={["inline-block", "none"]}>
          <LocaleSwitcher
            forDevice="mobile"
            billingRequestId={billingRequestId as string}
            isFulfilled={
              billingRequestStatus === BillingRequestsStatus.Fulfilled
            }
            billingRequest={billingRequest}
            billingRequestFlow={billingRequestFlow}
          />
        </Visibility>
      )}
    </Box>
  );
};

export const LocaleSwitcher = ({
  forDevice,
  billingRequestId,
  isFulfilled,
  billingRequest,
  billingRequestFlow,
}: {
  forDevice: string;
  billingRequestId: string;
  isFulfilled: boolean;
  billingRequest?: BillingRequestResource;
  billingRequestFlow?: BillingRequestFlowResource;
}) => {
  const log = Logger("BillingRequestUpdate", {
    billing_request_id: billingRequestId,
  });

  const { locale, setLocale } = useContext(GlobalState);

  const [, setI18nLocale] = useI18n();

  const persistLanguage = useCallback(
    (targetLocale: Locale) => {
      billingRequestUpdate(billingRequestId, {
        customer: {
          language: ShortLocalNames[targetLocale],
        },
      }).then(() => {
        log({
          message: "Customer's language updated",
        });
      });
    },
    [billingRequestId, log]
  );

  const handleUpdateLocale = useCallback(
    (newLocale: Locale) => {
      setI18nLocale(newLocale);
      setLocale(newLocale);
      // We need to set this value for accessibility.
      // We should look into using Next.js's native
      // internationalization to handle these changes
      document.documentElement.lang = newLocale;
    },
    [setI18nLocale, setLocale]
  );

  const changeLocale = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const targetLocale = e.target.value as Locale;
      handleUpdateLocale(targetLocale);

      if (!isFulfilled) {
        persistLanguage(targetLocale);
      }
    },
    [handleUpdateLocale, isFulfilled, persistLanguage]
  );

  useEffect(() => {
    // The locale will be null when the Global state is initially set up.
    if (!locale) {
      let userLocale;
      const customerLanguage = billingRequest?.resources?.customer?.language;

      if (
        customerLanguage &&
        (billingRequestFlow?.language || customerLanguage !== ShortLocale.EN)
      ) {
        userLocale = ShortToLongLocalNames[customerLanguage];
      }

      if (!userLocale) {
        userLocale = findBestMatchLocale();
      }

      handleUpdateLocale(userLocale);

      if (!isFulfilled) {
        persistLanguage(userLocale);
      }
    }
  }, []);

  return (
    <Box layout="flex" css={{ marginLeft: "20px", marginBottom: "4px" }}>
      <Select
        data-testid={`locale-switcher-${forDevice}`}
        name="locale-switcher"
        value={locale}
        onChange={changeLocale}
        css={{
          fontSize: "14px",
          fontWeight: "bold",
          boxShadow: "none",
          borderRadius: "40px",
          padding: "6px 4px 6px 16px",
        }}
      >
        {Object.keys(LocaleNames).map((localeName) => (
          <Option value={localeName} key={localeName}>
            {LocaleNames[localeName as Locale]}
          </Option>
        ))}
      </Select>
    </Box>
  );
};

export default Footer;
