import { TypePreset, FontWeight, Tooltip, Text } from "@gocardless/flux-react";
import { i18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";

const RecentlyUsedBankTooltip = () => {
  const toolTipContent = i18n._(
    t`It seems you've previously paid with this email, so we've pre-selected your last used bank. \
      To choose a different bank, tap "Change".`
  );

  return (
    <>
      <Text preset={TypePreset.Body_01} weight={FontWeight.Normal}>
        <Trans>Recently used bank</Trans>
      </Text>
      <Tooltip message={toolTipContent}>Trigger the tooltip</Tooltip>
    </>
  );
};

export default RecentlyUsedBankTooltip;
