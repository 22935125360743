import {
  Box,
  Color,
  ButtonSize,
  Text,
  FontWeight,
  XYGrid,
  DL,
  AlignItems,
  Space,
  P,
  DLLayout,
  DT,
  DD,
  ButtonVariant,
  ButtonLayout,
  Glyph,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import {
  ActionType,
  BillingRequestFlowResource,
  BillingRequestResource,
  InstitutionResource,
  BankAuthorisationResource,
} from "@gocardless/api/dashboard/types";
import {
  BrandedComponentType,
  getBrandColorFor,
  formatNameToTitleCase,
  isMandateOnlyFlow,
  usesOpenBankingGatewayAisAdapter,
  canShowAccountBalance,
  isBankRecurringFlow,
  leadBillingRequestScheme,
} from "src/common/utils";
import { getCustomerBankName } from "src/legacy-ui/helpers/getCustomerBankName";
import { isLocked } from "src/components/shared/Router";
import BrandedButton from "src/components/shared/BrandedComponents/BrandedButton";
import { GlobalState, PayerThemeType } from "src/state";
import { useLingui } from "@lingui/react";
import { customiseForScheme, Scheme } from "src/common/schemeCustomisations";
import { Icon } from "src/legacy-ui/icons";
import { useContext } from "react";

import SelectedInstitution from "./SelectedInstitution";
import { BankAccountFields } from "./BankAccountFields";

const BankDetails = ({
  billingRequest,
  billingRequestFlow,
  institution,
  onChangeInstitution,
  onAddAnotherAccount,
  payerTheme,
  bankAuthorisation,
}: {
  billingRequest: BillingRequestResource;
  billingRequestFlow: BillingRequestFlowResource;
  institution?: InstitutionResource;
  onChangeInstitution: () => void;
  onAddAnotherAccount: () => void;
  payerTheme?: PayerThemeType;
  bankAuthorisation?: BankAuthorisationResource;
}) => {
  const { i18n } = useLingui();

  const { isInstitutionGuessed } = useContext(GlobalState);

  const paymentRequest = billingRequest?.payment_request;

  const accountNumberEnding =
      billingRequest?.resources?.customer_bank_account?.account_number_ending,
    accountBalance =
      billingRequest?.resources?.customer_bank_account?.account_balance,
    accountCurrency =
      billingRequest?.resources?.customer_bank_account?.currency,
    accountHolderName = formatNameToTitleCase(
      billingRequest?.resources?.customer_bank_account?.account_holder_name
    ),
    branchCodeEnding =
      billingRequest?.resources?.customer_bank_account?.branch_code_ending,
    payId = billingRequest?.resources?.customer_bank_account?.pay_id;

  if (!accountNumberEnding && !institution) return null;

  const bankName = billingRequest?.resources?.customer_bank_account?.bank_name;

  const { bankIconName } = getCustomerBankName({ bank_name: bankName });

  const leadScheme = leadBillingRequestScheme(billingRequest);
  const isBankDetailsEditableForScheme = customiseForScheme({
    scheme: leadScheme as Scheme,
    key: "billing-request.bank-confirm.payment-agreement.editable-bank-account",
    params: { bankAuthorisation },
  });

  const isBankEditable =
    isBankDetailsEditableForScheme &&
    !isLocked(billingRequestFlow, ActionType.CollectBankAccount);

  const isBankAccountPresent = !!accountNumberEnding;

  const canDisplayAccountBalance =
    canShowAccountBalance(billingRequest) && accountBalance && accountCurrency;
  const customisation = customiseForScheme({
    scheme: leadBillingRequestScheme(billingRequest) as Scheme,
    key: "billing-request.bank-confirm.bank-details.show-not-your-bank-link",
    params: { billingRequest },
  });
  // This should never by null or undefined.
  // When undefined, the default value is used by SelectedInstitution.
  const canChangeInstitution =
    (customisation === undefined ? undefined : Boolean(customisation)) &&
    Boolean(isBankAccountPresent);

  const isACH = leadScheme === "ach";
  const showBankNameLabel =
    !isACH && !usesOpenBankingGatewayAisAdapter(billingRequest);
  return (
    <XYGrid
      templateAreas={`
        'bank_details change_bank'
        'fee_notice fee_notice'
      `}
      templateColumns="1fr max-content"
      autoColumns="100%"
      alignItems={AlignItems.Baseline}
    >
      <Box gridArea="bank_details">
        <DL layout={DLLayout.Stack}>
          {!payId && accountHolderName && (
            <>
              <DT size={[2, null, 3]} weight={FontWeight.Normal}>
                <Trans id="confirm-details-page.bank-details.account-holder-name">
                  Account holder name
                </Trans>
              </DT>
              <DD size={[2, null, 3]} weight={FontWeight.SemiBold}>
                {accountHolderName}
              </DD>
            </>
          )}
          {isMandateOnlyFlow(billingRequest) &&
            !isBankRecurringFlow(billingRequest) && (
              <>
                <Space v={1} />
                <XYGrid templateColumns="1fr" columnGap={1} rowGap={1}>
                  {showBankNameLabel && (
                    <Box>
                      <DT size={[2, null, 3]} weight={FontWeight.Normal}>
                        <Trans id="confirm-details-page.bank-details.bank-name">
                          Bank name
                        </Trans>
                      </DT>
                      <DD size={[2, null, 3]} weight={FontWeight.SemiBold}>
                        {bankName}
                      </DD>
                    </Box>
                  )}
                  {branchCodeEnding && (
                    <Box>
                      <DT size={[2, null, 3]} weight={FontWeight.Normal}>
                        <Trans id="confirm-details-page.bank-details.branch-code">
                          Sort code
                        </Trans>
                      </DT>
                      <DD size={[2, null, 3]} weight={FontWeight.SemiBold}>
                        <Box layout="flex" alignItems={AlignItems.Center}>
                          <Trans id="confirm-details-page.bank-details.branch-code-ending">
                            Ending ****{branchCodeEnding}
                          </Trans>
                          {bankIconName && (
                            <>
                              <Space layout="inline" h={0.5} />
                              <Icon name={bankIconName} size="24px" />
                            </>
                          )}
                        </Box>
                      </DD>
                    </Box>
                  )}
                  <BankAccountFields
                    isACH={isACH}
                    accountNumberEnding={accountNumberEnding}
                    bankName={bankName}
                  />
                  {canDisplayAccountBalance && (
                    <Box>
                      <DT size={[2, null, 3]} weight={FontWeight.Normal}>
                        <Trans id="confirm-details-page.bank-details.account-balance">
                          Account balance
                        </Trans>
                      </DT>
                      <DD size={[2, null, 3]} weight={FontWeight.SemiBold}>
                        {i18n.number(parseFloat(accountBalance as string), {
                          style: "currency",
                          currency: accountCurrency as string,
                          minimumFractionDigits: 2,
                        })}
                      </DD>
                    </Box>
                  )}
                </XYGrid>
              </>
            )}
          {(!isMandateOnlyFlow(billingRequest) ||
            isBankRecurringFlow(billingRequest)) && (
            <>
              {payId && (
                <>
                  <DT size={[2, null, 3]} weight={FontWeight.Normal}>
                    {/* TODO: Translations */}
                    Linked PayID
                  </DT>
                  <DT size={[2, null, 3]} weight={FontWeight.Normal}>
                    <Text weight={FontWeight.Bold}>{payId}</Text>
                  </DT>
                </>
              )}
              {!payId && isBankAccountPresent && (
                <DT size={[2, null, 3]} weight={FontWeight.Normal}>
                  <Trans id="confirm-details-page.bank-details.bank-account-ending">
                    Bank account ending with
                  </Trans>{" "}
                  <Text weight={FontWeight.Bold}>
                    ******{accountNumberEnding}
                  </Text>
                </DT>
              )}
              <DD size={[2, null, 3]} weight={FontWeight.SemiBold}>
                {institution && onChangeInstitution && (
                  <SelectedInstitution
                    canChangeInstitution={canChangeInstitution}
                    selectedInstitution={institution}
                    onChangeInstitution={onChangeInstitution}
                    payerTheme={payerTheme}
                    isInstitutionGuessed={isInstitutionGuessed}
                  />
                )}
              </DD>
            </>
          )}
        </DL>
      </Box>
      {isBankEditable && (
        <Box gridArea="change_bank">
          <BrandedButton
            textColor={getBrandColorFor(BrandedComponentType.Link, payerTheme)}
            data-testid="change-bank-button"
            variant={ButtonVariant.Inline}
            onClick={
              !isBankAccountPresent ? onChangeInstitution : onAddAnotherAccount
            }
            size={ButtonSize.Sm}
            layout={ButtonLayout.Inline}
            leftIcon={Glyph.Edit}
            id="changeBank"
          >
            <>
              <Trans id="confirm-details-page.form.change-button">Change</Trans>
            </>
          </BrandedButton>
        </Box>
      )}
      {paymentRequest?.scheme &&
        customiseForScheme({
          scheme: paymentRequest?.scheme as Scheme,
          key: "billing-request.bank-confirm.show-fee-notice",
        }) && (
          <Box gridArea="fee_notice">
            <Space v={1} />
            <Box
              bg={Color.Greystone_100}
              gutterH={[0.5]}
              gutterV={[0.5]}
              borderRadius={1}
              css={{ width: "fit-content" }}
              data-testid="bank-details.additional-fee-notice"
            >
              <P size={2}>
                <Trans id="confirm-details-page.bank-details.additional-fee-notice">
                  Your bank may charge a fee to process this payment
                </Trans>
              </P>
            </Box>
          </Box>
        )}
    </XYGrid>
  );
};

export default BankDetails;
