import {
  BillingRequestResource,
  DayOfWeek,
  InstalmentScheduleRequestObject,
  InstalmentsWithDate,
  InstalmentTemplateResource,
  IntervalUnit,
  SourceCurrencyEnum,
} from "@gocardless/api/dashboard/types";
import { INSTANT_PAY_VARIANTS } from "src/common/consts";
import { PaymentVariant } from "src/common/payments";
import { Scheme } from "src/common/scheme";
import { addMonths, addWeeks, addYears, format } from "date-fns";
import { i18n } from "@lingui/core";
import { t, selectOrdinal, select } from "@lingui/macro";

export const isInstantBankPay = (
  paymentVariant: PaymentVariant | null,
  billingRequest: BillingRequestResource
) => {
  const instantPaySchemeExclusions = [Scheme.Ach, Scheme.Pad];
  const isVariantIBP =
    paymentVariant && INSTANT_PAY_VARIANTS.includes(paymentVariant);

  const isSchemeExcluded =
    billingRequest.payment_request?.scheme &&
    instantPaySchemeExclusions.includes(
      billingRequest.payment_request.scheme as Scheme
    );

  return Boolean(isVariantIBP && !isSchemeExcluded);
};

export const getFirstInstalmentDate = (
  instalmentScheduleRequest: InstalmentScheduleRequestObject
) => {
  const { instalments_with_dates, instalments_with_schedule } =
    instalmentScheduleRequest;

  if (instalments_with_schedule) {
    return new Date(instalments_with_schedule.start_date ?? "");
  }

  if (instalments_with_dates?.length) {
    return new Date(instalments_with_dates[0]?.charge_date ?? "");
  }

  return;
};

const getValidChargeDate = (date?: Date) => {
  // Only return the Date object if date is in the future,
  // otherwise, return undefined.
  if (!date) {
    return undefined;
  }
  const chargeDate = new Date(date);
  const currentDate = new Date();
  // Set the time of the current date to midnight to compare only the date part
  currentDate.setHours(0, 0, 0, 0);
  chargeDate.setHours(0, 0, 0, 0);

  const chargeDateIsInPast = chargeDate < currentDate;

  if (chargeDateIsInPast) {
    return undefined;
  }
  return chargeDate;
};

export const transformInstalmentData = (
  instalmentScheduleRequest: InstalmentScheduleRequestObject
): InstalmentTemplateResource => {
  const { name, currency, instalments_with_dates, instalments_with_schedule } =
    instalmentScheduleRequest;
  const instalmentCurrency = currency as SourceCurrencyEnum;

  if (instalments_with_schedule) {
    const start_date = getValidChargeDate(
      instalments_with_schedule.start_date ?? undefined
    );
    const day_of_month = start_date?.getDate();
    const day_of_week = start_date
      ? (format(start_date.getDay(), "EEEE").toLowerCase() as DayOfWeek)
      : undefined;
    const interval_unit =
      instalments_with_schedule.interval_unit as IntervalUnit;

    const transformedInstalments: InstalmentsWithDate[] =
      instalments_with_schedule.amounts.reduce(
        (acc: InstalmentsWithDate[], amount: string | number) => {
          const parsedAmount = parseInt(amount.toString());
          if (!acc.length || !start_date) {
            return [
              ...acc,
              { charge_date: start_date ?? null, amount: parsedAmount },
            ];
          }
          const previousChargeDate = acc.at(-1)?.charge_date;
          if (!previousChargeDate) {
            return acc;
          }
          if (interval_unit === IntervalUnit.Monthly) {
            const charge_date = addMonths(previousChargeDate, 1);
            return [...acc, { charge_date, amount: parsedAmount }];
          }
          if (interval_unit === IntervalUnit.Weekly) {
            const charge_date = addWeeks(previousChargeDate, 1);
            return [...acc, { charge_date, amount: parsedAmount }];
          }
          if (interval_unit === IntervalUnit.Yearly) {
            const charge_date = addYears(previousChargeDate, 1);
            return [...acc, { charge_date, amount: parsedAmount }];
          }
          return acc;
        },
        []
      );
    return {
      name,
      day_of_month,
      day_of_week,
      interval_unit,
      currency: instalmentCurrency,
      instalments: transformedInstalments,
    };
  }

  if (instalments_with_dates) {
    const mappedInstalments = instalments_with_dates.map(
      ({ charge_date, amount }) => ({
        charge_date: getValidChargeDate(charge_date ?? undefined),
        amount,
      })
    );

    // Check if any charge_date is in the past
    const hasPastChargeDate = instalments_with_dates.some(({ charge_date }) =>
      Boolean(!getValidChargeDate(charge_date ?? undefined))
    );

    const instalmentsWithDates = !hasPastChargeDate
      ? mappedInstalments
      : mappedInstalments.map(({ amount }) => ({
          charge_date: undefined,
          amount,
        }));

    return {
      name,
      day_of_month: undefined,
      day_of_week: undefined,
      interval_unit: undefined,
      currency: instalmentCurrency,
      instalments: instalmentsWithDates as InstalmentsWithDate[],
    };
  }

  return {
    name,
    instalments: undefined,
    currency: instalmentCurrency,
    day_of_month: undefined,
    day_of_week: undefined,
    interval_unit: undefined,
  };
};

export const getFirstInstalmentPaymentMessage = (date: Date) => {
  // Return undefined if date is in the past
  if (!getValidChargeDate(date)) {
    return undefined;
  }
  const firstPaymentDate = date.getDate();
  const firstPaymentMonth = format(date, "MMMM").toLowerCase();

  return i18n._(
    t({
      message: `First installment billed ${selectOrdinal(firstPaymentDate, {
        one: "on the #st",
        two: "on the #nd",
        few: "on the #rd",
        many: "on the #th",
        other: "on the #th",
      })} ${select(firstPaymentMonth, {
        january: "of January",
        february: "of February",
        march: "of March",
        april: "of April",
        may: "of May",
        june: "of June",
        july: "of July",
        august: "of August",
        september: "of September",
        october: "of October",
        november: "of November",
        december: "of December",
        other: " ",
      })}`,
    })
  );
};
