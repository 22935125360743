import {
  ColorPreset,
  ColorScheme,
  FontWeight,
  Glyph,
  Space,
  Tag,
  TagColor,
  TagSize,
  TagVariant,
  Text,
  Tooltip,
} from "@gocardless/flux-react";

const TemporarilyDisabledInstitution = () => {
  const toolTipContent = (
    <Text>
      {/* TODO: Add translation */}
      This bank is temporarily disabled for payments. Please choose a different
      bank.
    </Text>
  );

  return (
    <>
      <Space h={0.5} layout="inline" />
      <Tooltip placement="right-end" message={toolTipContent}>
        {(triggerProps) => {
          return (
            <Tag
              variant={TagVariant.Tinted}
              color={TagColor.Neutral}
              size={TagSize.Md}
              colorScheme={ColorScheme.OnLight}
              iconBefore={Glyph.Tooltip}
              {...triggerProps}
            >
              <Text
                weight={FontWeight.Normal}
                color={ColorPreset.TextOnLight_01}
              >
                Temporarily Disabled
              </Text>
            </Tag>
          );
        }}
      </Tooltip>
    </>
  );
};

export default TemporarilyDisabledInstitution;
