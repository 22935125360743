import { isMobile } from "react-device-detect";
import { useLingui } from "@lingui/react";
import { t, select, Trans } from "@lingui/macro";
import {
  Space,
  XYGrid,
  FontWeight,
  P,
  TextAlign,
  MoneyText,
  MoneyTextVariant,
  Separator,
  TypePreset,
  ColorPreset,
} from "@gocardless/flux-react";
import {
  MandateRequestConstraints,
  PeriodEnum,
} from "@gocardless/api/dashboard/types";
import { PeriodFrequency, localiseFrequency } from "src/common/frequency";
import { isDateInThePast } from "src/common/utils";
import { LocaleDateString } from "src/components/shared/date";
import { useI18n } from "src/common/i18n";
import { Money } from "src/common/money";
import { Fragment } from "react";

export const localeDateFormat: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

export interface ConsentParametersProps {
  currency: string;
  constraints?: MandateRequestConstraints | null;
  mandateDescription?: string | null;
  paymentDescription?: string | null;
  paymentAmount?: string | number;
  showOptional: boolean;
}

interface ConsentParametersConfig {
  label: string;
  value?: React.ReactElement | string | number | null;
  visible: boolean;
}

const ConsentParameters = ({
  currency,
  constraints,
  mandateDescription,
  paymentDescription,
  paymentAmount,
  showOptional,
}: ConsentParametersProps) => {
  const { i18n } = useLingui();
  const [locale] = useI18n();
  const periodConfig =
    constraints?.periodic_limits && constraints?.periodic_limits[0];
  const frequency =
    periodConfig?.period && PeriodFrequency[periodConfig.period];
  const localisedFrequency = frequency && localiseFrequency()[frequency];
  const isFlexible = periodConfig?.period === PeriodEnum.Flexible;
  const hasRecurringPayment = Boolean(mandateDescription || constraints);

  const consentParametersConfig: ConsentParametersConfig[] = [
    {
      label: i18n._(
        t({
          id: "consent-parameters.description",
          message: "Description",
        })
      ),
      value: mandateDescription,
      visible: Boolean(mandateDescription),
    },
    {
      label: i18n._(
        t({
          id: "consent-parameters.frequency",
          message: "Payment Frequency",
        })
      ),
      value: localisedFrequency,
      visible: Boolean(localisedFrequency) && !isFlexible,
    },
    {
      label: i18n._(
        t({
          id: "consent-parameters.maximum-payments-per-period",
          message: `Maximum payments ${select(periodConfig?.period || "", {
            year: "per year",
            month: "per month",
            week: "per week",
            day: "per day",
            other: "per period",
          })}`,
        })
      ),
      value: periodConfig?.max_payments,
      visible: showOptional && Boolean(periodConfig?.max_payments),
    },
    {
      label: i18n._(
        t({
          id: "consent-parameters.maximum-amount-per-period",
          message: `Maximum amount ${select(periodConfig?.period || "", {
            year: "per year",
            month: "per month",
            week: "per week",
            day: "per day",
            other: "per period",
          })}`,
        })
      ),
      value: (
        <MoneyText
          // this value will not be visible when not passed so
          // we can set optional
          amount={Money.toUnit(periodConfig?.max_total_amount) || 0}
          currency={currency}
          locale={locale}
          format="short"
          variant={MoneyTextVariant.Flat}
        />
      ),
      visible: Boolean(periodConfig?.max_total_amount),
    },
    {
      label: i18n._(
        t({
          id: "consent-parameters.maximum-amount-per-payment",
          message: "Maximum amount per payment",
        })
      ),
      value: (
        <MoneyText
          // this value will not be visible when not passed so
          // we can set optional
          amount={Money.toUnit(constraints?.max_amount_per_payment) || 0}
          currency={currency}
          locale={locale}
          format="short"
          variant={MoneyTextVariant.Flat}
        />
      ),
      visible: Boolean(constraints?.max_amount_per_payment),
    },
    {
      label: i18n._(
        t({
          id: "consent-parameters.start-date",
          message: "Start Date",
        })
      ),
      value:
        constraints?.start_date &&
        (isDateInThePast(constraints.start_date) ? (
          i18n._(
            t({
              id: "consent-parameters.today",
              message: "Today",
            })
          )
        ) : (
          <LocaleDateString
            date={constraints.start_date}
            options={localeDateFormat}
          />
        )),
      visible: showOptional && Boolean(constraints?.start_date) && !isFlexible,
    },
    {
      label: i18n._(
        t({
          id: "consent-parameters.end-date",
          message: "End Date",
        })
      ),
      value: constraints?.end_date ? (
        <LocaleDateString
          date={constraints?.end_date}
          options={localeDateFormat}
        />
      ) : (
        i18n._(
          t({
            id: "consent-parameters.ongoing",
            message: "Ongoing",
          })
        )
      ),
      visible: showOptional && hasRecurringPayment,
    },
  ];

  return (
    <>
      {Boolean(paymentAmount) && (
        <>
          <P preset={TypePreset.Heading_02} weight={FontWeight.SemiBold}>
            <Trans id="consent-parameters.due-today">Due today</Trans>
          </P>
          <Space v={0.5} />
          <div data-testid="upfront-payment-attributes">
            <XYGrid
              rowGap={0.25}
              templateColumns={isMobile ? "auto" : "220px 1fr"}
              autoColumns="auto"
            >
              <LabelledParameter
                label={i18n._(
                  t({
                    id: "consent-parameters.description",
                    message: "Description",
                  })
                )}
              >
                {paymentDescription}
              </LabelledParameter>
              <LabelledParameter
                label={i18n._(
                  t({
                    id: "consent-parameters.upfront-payment",
                    message: "Upfront payment",
                  })
                )}
              >
                <MoneyText
                  amount={Money.toUnit(paymentAmount)}
                  currency={currency}
                  locale={locale}
                  format="short"
                  variant={MoneyTextVariant.Flat}
                />
              </LabelledParameter>
            </XYGrid>
          </div>
          <Separator spacing={[[1, 0]]} />
        </>
      )}
      <div data-testid="consent-parameters">
        {Boolean(paymentAmount) && hasRecurringPayment && (
          <P preset={TypePreset.Heading_02} weight={FontWeight.SemiBold}>
            <Trans id="consent-parameters.recurring-payment">
              Recurring payment
            </Trans>
            <Space v={0.5} />
          </P>
        )}
        <XYGrid
          rowGap={0.25}
          templateColumns={isMobile ? "auto" : "220px 1fr"}
          autoColumns="auto"
        >
          {consentParametersConfig.map(
            (consentParameter: ConsentParametersConfig) => {
              if (!consentParameter.visible) return null;

              return (
                <LabelledParameter
                  label={consentParameter.label}
                  key={consentParameter.label}
                >
                  {consentParameter.value}
                </LabelledParameter>
              );
            }
          )}
        </XYGrid>
      </div>
    </>
  );
};

export const LabelledParameter = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => (
  <Fragment key={label}>
    <P preset={TypePreset.Body_01} color={ColorPreset.TextOnLight_03}>
      {label}
    </P>
    <P
      preset={TypePreset.Body_01}
      textAlign={isMobile ? TextAlign.Left : TextAlign.Right}
      weight={FontWeight.SemiBold}
      color={ColorPreset.TextOnLight_01}
    >
      {children}
    </P>
    {isMobile && <Space v={0.75} />}
  </Fragment>
);

export default ConsentParameters;
