import {
  InstitutionResource,
  InstitutionStatus,
} from "@gocardless/api/dashboard/types";
import {
  AlignItems,
  Box,
  FontWeight,
  Space,
  SpaceScale,
  Text,
  TextAlign,
} from "@gocardless/flux-react";
import TemporarilyDisabledInstitution from "src/components/shared/TemporarilyDisabledInstitution";

import RecentlyUsedBankTooltip from "../RecentlyUsedBankTooltip";

const InstitutionWithIcon = ({
  institution,
  showRecentlyUsedBankTooltip = false,
  hSpaceScale,
  iconHeight,
  iconWidth,
}: {
  institution?: InstitutionResource;
  showRecentlyUsedBankTooltip?: boolean;
  hSpaceScale?: SpaceScale;
  iconHeight?: string;
  iconWidth?: string;
}) => {
  const isTemporarilyDisabled =
    institution?.status === InstitutionStatus.TemporarilyDisabled;

  return (
    <Box
      layout="flex"
      flexWrap="nowrap"
      data-testid="institution-with-icon"
      alignItems={AlignItems.Center}
      className="fs-exclude"
    >
      <img
        src={institution?.icon_url}
        height={iconHeight || "35px"}
        width={iconWidth || "35px"}
        alt={institution?.name || "Institution icon"}
        style={{ opacity: isTemporarilyDisabled ? 0.3 : 1 }}
      />
      <Space h={hSpaceScale || 0.5} layout="inline" />

      <Box>
        <Box>
          <Text
            textAlign={TextAlign.Left}
            size={[2, 3]}
            weight={FontWeight.SemiBold}
            style={{ opacity: isTemporarilyDisabled ? 0.3 : 1 }}
          >
            {institution?.name}
          </Text>
          {isTemporarilyDisabled && <TemporarilyDisabledInstitution />}
        </Box>
        {showRecentlyUsedBankTooltip && (
          <Box>
            <RecentlyUsedBankTooltip />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default InstitutionWithIcon;
