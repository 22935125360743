import { BillingRequestResource } from "@gocardless/api/dashboard/types";
import { useContext } from "react";
import { OptimizelyContext } from "@optimizely/react-sdk";
import { GlobalState } from "src/state";
import { Locale, useI18n } from "src/common/i18n";

export const useShouldDisplayCustomerPreferences = (
  billingRequest: BillingRequestResource
): Boolean => {
  const [locale] = useI18n();
  const { optimizely } = useContext(OptimizelyContext);
  const { showReturningCustomerVerification } = useContext(GlobalState);
  const optimizely_decision = optimizely?.decide(
    "payer_prop__remember_me",
    [],
    billingRequest.id,
    {
      organisation_id: billingRequest?.links?.organisation,
      customer_id: billingRequest.links?.customer,
      scheme: billingRequest.mandate_request?.scheme,
    }
  );

  return !!(
    optimizely_decision?.enabled &&
    billingRequest.mandate_request?.scheme === "bacs" &&
    !showReturningCustomerVerification &&
    locale === Locale.EN_GB
  );
};

export const useReturnFlowEnabled = (
  billingRequest: BillingRequestResource
): Boolean => {
  const { optimizely } = useContext(OptimizelyContext);
  const [locale] = useI18n();
  const optimizely_decision =
    optimizely?.decide(
      "payer_prop__remember_me_return_flow",
      [],
      billingRequest.id,
      {
        organisation_id: billingRequest?.links?.organisation,
        customer_id: billingRequest.links?.customer,
        scheme: billingRequest.mandate_request?.scheme,
      }
    ).variationKey === "on";

  return optimizely_decision && locale === Locale.EN_GB;
};
