import {
  BillingRequestFlowResource,
  BillingRequestResource,
} from "@gocardless/api/dashboard/types";
import {
  Box,
  Color,
  Container,
  JustifyContent,
  useTheme,
  XYGrid,
  Reset as ResetNext,
  Separator,
  CSSRulesFunction,
  Space,
  ColorPreset,
} from "@gocardless/flux-react";
import { ReactNode } from "react";
import ExitButton from "src/components/shared/ExitButton";
import { PayerThemeType } from "src/state";
import { RuntimeMode } from "src/state/RuntimeModeInitialiser";

import { Routes } from "../Router";

import Feedback from "./Feedback";
import Footer from "./Footer";

const MAX_WIDGET_WIDTH_LG = "824px";
const MAX_WIDGET_WIDTH_SM = "712px";

const MAX_CARD_WIDTH_SM = "664px";
const MAX_CARD_WIDTH_DEFAULT = "696px";

const MAX_CONTENT_WIDTH = "568px";
const LOGO_HEADER_DIVIDER_COLOR = Color.Greystone_1400_A16;

export const SingleFixedLayout = ({
  children,
  runtimeMode,
  billingRequest,
  billingRequestFlow,
  page,
  onExitOverride,
  hideExitButton,
  payerTheme,
  secondaryPanel,
}: {
  children: React.ReactNode;
  runtimeMode?: RuntimeMode;
  billingRequest?: BillingRequestResource;
  billingRequestFlow?: BillingRequestFlowResource;
  page?: Routes;
  onExitOverride?: () => void;
  hideExitButton?: boolean;
  payerTheme?: PayerThemeType;
  secondaryPanel?: ReactNode;
}) => {
  const { theme } = useTheme();
  const logoTheme = {
    logoURL: billingRequestFlow?.config?.creditor_logo_url,
    backgroundColor: payerTheme?.header_background_colour,
  };

  const logoURL = logoTheme.logoURL;

  let backgroundColor = Color.Greystone_200;
  if (runtimeMode === RuntimeMode.Dropin) {
    backgroundColor = Color.Greystone_1400_A90;
  }

  const templateAreas = secondaryPanel ? `"primary" "secondary"` : `"primary"`;

  const isPaymentProvider = !!billingRequest?.links?.payment_provider;

  const showFeedbackLink = !isPaymentProvider && page && billingRequest;

  return (
    <>
      <ResetNext />
      <Box bg={backgroundColor} minHeight="100dvh">
        <Box
          maxWidth={[
            MAX_WIDGET_WIDTH_SM,
            MAX_WIDGET_WIDTH_SM,
            MAX_WIDGET_WIDTH_LG,
            MAX_WIDGET_WIDTH_LG,
          ]}
          gutterH={[0, 1.5, 2, 4]}
          gutterV={[0, 4, 4, 4]}
          css={{ margin: "0 auto" }}
        >
          <Box
            maxWidth={[
              MAX_CARD_WIDTH_SM,
              MAX_CARD_WIDTH_DEFAULT,
              MAX_CARD_WIDTH_DEFAULT,
            ]}
            css={{ margin: "0 auto" }}
          >
            {logoURL && (
              <Box
                width="100%"
                css={[
                  logoStyle(theme, {
                    backgroundColor: logoTheme?.backgroundColor,
                  }),
                ]}
              >
                <Box layout="flex" justifyContent={JustifyContent.SpaceBetween}>
                  <Box
                    gutterH={[1.5, 3, 4, 4]}
                    spaceAbove={[2, 4]}
                    spaceBelow={[1.5, 3]}
                  >
                    <Box maxWidth={[200, 280]}>
                      <img
                        src={logoURL || ""}
                        alt="Logo"
                        css={{
                          maxHeight: "40px",
                        }}
                      />
                    </Box>
                  </Box>
                  {hideExitButton ? null : (
                    <Box>
                      <ExitButton onExitOverride={onExitOverride} />
                    </Box>
                  )}
                </Box>
                <LogoDivider />
              </Box>
            )}
            <Box
              bg={Color.White}
              css={[layoutBorderStyle(theme, { hasLogo: !!logoURL })]}
              gutterH={[1.5, 3, 4, 4]}
            >
              <Box maxWidth={MAX_CONTENT_WIDTH} css={{ margin: "0 auto" }}>
                <XYGrid templateAreas={templateAreas} templateRows="1fr auto">
                  <Box gridArea="primary" height="100%" css={{ zIndex: 5 }}>
                    {!logoURL && !hideExitButton ? (
                      <ExitButton onExitOverride={onExitOverride} />
                    ) : null}
                    <Box>{children}</Box>
                  </Box>
                  {secondaryPanel && (
                    <Box gridArea="secondary" gutterV={2}>
                      {secondaryPanel}
                    </Box>
                  )}
                </XYGrid>
              </Box>
            </Box>
            <Box
              gutterH={[1, 2, 4]}
              spaceAbove={[2]}
              layout="flex"
              justifyContent={JustifyContent.Center}
            >
              <Container maxContentWidth={MAX_CONTENT_WIDTH} contentGutters={0}>
                <Footer
                  runtimeMode={runtimeMode}
                  billingRequest={billingRequest}
                  billingRequestFlow={billingRequestFlow}
                  page={page}
                  payerTheme={payerTheme}
                />
              </Container>
            </Box>
            {showFeedbackLink ? (
              <Box spaceAbove={[0.5, 1]}>
                <Feedback page={page} billingRequest={billingRequest} />
                <Space v={[4, 0, null, null]} />
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </>
  );
};

const logoStyle: CSSRulesFunction<{
  backgroundColor: string | undefined;
}> = (theme, { backgroundColor }) => [
  theme.responsive(
    [
      theme.radius(0),
      `${theme.radius(1.5)} ${theme.radius(1.5)} ${theme.radius(0)} ${theme.radius(0)}`,
    ],
    (value) => ({
      borderRadius: value,
    })
  ),
  {
    backgroundColor: backgroundColor ?? theme.color(Color.White),
    lineHeight: 0,
  },
];

const layoutBorderStyle: CSSRulesFunction<{
  hasLogo: boolean;
}> = (theme, { hasLogo }) => [
  theme.responsive(
    hasLogo
      ? [
          `${theme.radius(0)} ${theme.radius(0)} ${theme.radius(0)} ${theme.radius(0)}`,
          `${theme.radius(0)} ${theme.radius(0)} ${theme.radius(1.5)} ${theme.radius(1.5)}`,
        ]
      : [
          `${theme.radius(0)} ${theme.radius(0)} ${theme.radius(0)} ${theme.radius(0)}`,
          theme.radius(1.5),
        ],
    (value) => ({
      borderRadius: value,
    })
  ),
  theme.responsive(
    [`1px solid ${theme.color(ColorPreset.BorderOnLight_04)}`, "none"],
    (value) => ({
      borderBottom: value,
    })
  ),
  theme.responsive(
    hasLogo
      ? [`${theme.spacing(2)}`, `${theme.spacing(3)}`]
      : [`${theme.spacing(2)}`, `${theme.spacing(4)}`],
    (value) => ({
      paddingTop: value,
    })
  ),
  theme.responsive([`${theme.spacing(3)}`, `${theme.spacing(4)}`], (value) => ({
    paddingBottom: value,
  })),
];

const LogoDivider = () => {
  return (
    <Box gutterH={[0, 3, 4, 4]} css={{ margin: "0 auto" }}>
      <Separator spacing={0} thickness={1} color={LOGO_HEADER_DIVIDER_COLOR} />
    </Box>
  );
};

export default SingleFixedLayout;
